body {
  margin: 0;
  padding: 0 220px;
  line-height: 1.3;
  background: #021e44;
  background-attachment: fixed;
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1050px) {
  body {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 730px) {
  body {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 420px) {
  body {
    padding: 0 22px;
  }
}

// * {
// 	background-color: rgba(255, 0, 0, 0.2);
// }
// * * {
// 	background-color: rgba(0, 255, 0, 0.2);
// }
// * * * {
// 	background-color: rgba(0, 0, 255, 0.2);
// }
// * * * * {
// 	background-color: rgba(255, 0, 255, 0.2);
// }
// * * * * * {
// 	background-color: rgba(0, 255, 255, 0.2);
// }
// * * * * * * {
// 	background-color: rgba(255, 255, 0, 0.2);
// }
// * * * * * * * {
// 	background-color: rgba(255, 0, 0, 0.2);
// }
// * * * * * * * * {
// 	background-color: rgba(0, 255, 0, 0.2);
// }
// * * * * * * * * * {
// 	background-color: rgba(0, 0, 255, 0.2);
// }
