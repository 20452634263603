.Contact {
	width: 100%;
	min-height: 600px;
	padding: 100px 0 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
	justify-content: flex-start;

	h2 {
		margin-bottom: 40px;
		font-family: 'Overpass';
		font-size: 40px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		top: 23px;
		padding-right: 15px;
		color: white;
		transition: all 200ms ease-out;

		span {
			font-family: 'Overpass Mono';
			font-size: 15px;
			color: #64ffda;
			margin-bottom: 5px;
		}
	}

	p {
		width: 500px;
		text-align: center;
		margin-top: -8px;
		font-family: 'Overpass Mono';
		font-size: 16px;
		transition: all 200ms ease-out;
		margin-bottom: -50px;
	}

	svg {
		margin: 0px;
		padding: 0px;
		width: 63%;
	}

	.email-button {
		margin-top: 50px;
		transition: all 200ms ease-out;
	}

	a {
		background-color: #64ffda;
		position: relative;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		transition: all 200ms ease-out;
		box-shadow: 0 0 15px #ff3e5425;

		&:hover {
			background: #0b1429;
			box-shadow: 0 0 15px rgba(0, 56, 139, 0.192);

			.st0 {
				fill: #64ffda;
			}
		}
	}

	.st0 {
		fill: black;
		transition: all 200ms ease-out;
	}
}

@media only screen and (max-width: 1100px) {
	.Contact {
		min-height: 520px;
		margin-top: 120px;
	}
}

@media only screen and (max-width: 730px) {
	.Contact {
		min-height: 520px;
		margin-top: 140px;
		margin-bottom: -10px;

		p {
			font-size: 18px;
			width: 400px;
		}

		h2 {
			font-size: 40px;
		}

		a {
			background-color: #0b1429;
			position: relative;
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			transition: all 200ms ease-out;
			box-shadow: 0 0 15px #ff3e5425;

			&:hover {
				background: #64ffda;
				box-shadow: 0 0 15px rgba(0, 56, 139, 0.192);

				.st0 {
					fill: #0b1429;
				}
			}
		}
		.st0 {
			fill: #64ffda;
			transition: all 200ms ease-out;
		}
	}
}

@media only screen and (max-width: 600px) {
	.Contact {
		margin-top: -250px;
	}
}

@media only screen and (max-width: 550px) {
	.Contact {
		margin-top: -120px;

		p {
			font-size: 18px;
			width: 400px;
		}

		h2 {
			font-size: 40px;
		}
	}
}

@media only screen and (max-width: 430px) {
	.Contact {
		min-height: 440px;

		p {
			font-size: 12px;
			width: 320px;
			margin-bottom: -50px;
		}

		h2 {
			font-size: 30px;
			margin-bottom: 35px;

			span {
				font-size: 12px;
				margin-bottom: 10px;
			}
		}
	}
}
